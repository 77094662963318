#root {
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background: #ccc4d5;
  margin: 1rem;
}
.memory-game {
  margin: 1rem;
}

.memory-card:active {
  transform: scale(0.97);
  transition: transform 0.2s;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.memory-card.flip {
  transform: rotateY(360deg);
}

.name-card {
  text-align: center;
  color: #c8bcd1;
  font-size: 1.5em;
}
.matched img,
.matched span {
  background: #5c3a78 !important;
}

.parent {
  max-width: 90vh;
  height: 100%;
  margin: auto;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: center;
  align-items: center;
}
.square {
  border-radius: 5px;
  position: relative;
  border: 1rem solid #78638f;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5em;
  background: #5c3a78 !important;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #c8bcd1;
  font-size: 1.5em;
  background-color: #78638f;
}
